import { Link } from "gatsby";
import React, { ReactElement } from "react";
import Heading from "../../components/heading";
import FilledButton from "../filled-button";

export interface HCSec5Props { }

export default function HCSec5(props: HCSec5Props): ReactElement | null {
  const data = [
    {
      title: "Free To Use, no hidden fee",
      desc: "No origination fees, closing fees, or prepayment penalties. No fees, as long as you pay on time.",
      icon: require("../../images/free-to-use.svg").default,
      color: "#D87559"
    },
    {
      title: "One simple form to start",
      desc: "Apply in just minutes with our quick application. That means whenever you’re ready, so are we.",
      icon: require("../../images/simple-form.svg").default,
      color: "#D87559"
    },
    {
      title: "Fast, secure and here for you ",
      desc: "Our team is here to help you reach your financial goals. Our expert Client Success team is here to help you along the way.",
      icon: require("../../images/fast-secure.svg").default,
      color: "#D87559"
    },
    {
      title: "More lenders, more options",
      desc: "We can help connect you to lenders that offer loans that may work for you. Through our service, you may be able to connect with a lender regardless of your credit history.",
      icon: require("../../images/more-lenders.svg").default,
      color: "#D87559"
    },

  ]
  return (
    <>
      <div className=" -max-w-1366 mx-auto p-8">
        <Heading position="center">
          <h2 className="text-center">
            Why Choose Us?
          </h2>
        </Heading>
        <div
          className="mt-16 mx-auto"
          style={{ maxWidth: "1024px" }}
        >
          <div className="flex flex-wrap">
            {data.map((item, index) => <div className="w-full md:w-1/2 mb-8" key={index}>
              <div className="flex items-start " >
                <img src={item.icon} />
                <div className="pl-2">
                  <div className="flex items-end mb-2">
                    <h1 style={{ color: item.color }} className="text-6xl pr-1" >{item.title && item.title.length ? item.title.substring(0, 1) : ""}</h1>
                    <h4 >{item.title}</h4>
                  </div>
                  <p className="">{item.desc}</p>
                </div>
              </div>

            </div>)}
          </div>
        </div>
      </div>
      <div className="flex justify-center ">
        <div className="text-center">
          <h1>TO GIVE YOU TOTAL PEACE OF MIND</h1>
          <div className="mt-4 w-full md:w-1/2 mx-auto font-bold px-8 md:px-0 text-justify md:text-center">
            If you are applying for a loan online, it is important that you choose a
            reputable company. Before you apply, here's a little bit of information
            to put your mind at ease.
          </div>
          <div className="flex justify-center mt-8">
            <Link to="/apply">
              <FilledButton>APPLY NOW</FilledButton>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
